.contact-wrapper {
  form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    label {
      @include set-font-size($fz-lable);
      padding: 0.25rem 0;
    }
    select,
    input,
    textarea {
      background-color: rgb(27, 27, 27);
      color: $white;
      border: none;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      &:focus {
        outline: $white 1px solid;
      }
    }
    textarea {
      height: auto;
      min-height: 200px;
    }
    %contact-btn {
      display: inline-block;
      padding: 0.75rem 1rem;
      margin: 1rem 0;
      outline: 0;
      cursor: pointer;
      transition: all 0.3s ease;
      @include set-font-size($fz-lable);
      margin: 2rem 0;
    }
    .loading-btn {
      @extend %contact-btn;
      background-color: $offWhite;
      color: $black;
    }
    .submit-btn {
      @extend %contact-btn;
      background-color: $blue;
      color: $white;
      border: none;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.disabled {
  display: none;
}

.thank-you {
  display: grid;
  place-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10rem 0;
}

.lang-switcher {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.lang-switcher input[type="radio"] {
  display: none;
}

/* === 各ラジオボタンのラベルをボタンに変更 == */
.lang-switcher label {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: 2px 2px #999;
  padding: 2px 6px;
}

/* === 選択されていない言語は非表示にする ==== */
#langJa:not(:checked) ~ * *:lang(ja) {
  display: none;
}
#langEn:not(:checked) ~ * *:lang(en) {
  display: none;
}

/* === 選択されている言語のラベル色を変更 ==== */
#langJa:checked ~ label[for="langJa"] {
  background: $offWhite;
}
#langEn:checked ~ label[for="langEn"] {
  background: $offWhite;
}
