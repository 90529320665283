.top-section{
  padding: 3rem 0;
}

.top-title {
  padding: 2rem 0;
  text-align: center;
  @include set-font-size($fz-headline);
  letter-spacing: 0.05em;
}

.page-header {
  padding: 10rem 0 4rem 0;
  @include gridCenter();
  position: relative;
  h1 {
    @include set-font-size($fz-display2);
  }
}

article {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  p {
    font-weight: 200;
    @include set-font-size($fz-lable);
    padding: 0.25rem 0;
    font-feature-settings : "pkna" 1;
  }
  img {
    width: 100%;
    max-width: 50rem;
  }
  a {
    color: $white;
    text-decoration: underline;
    padding: 0 0.25rem;
    transition: all 0.3s ease;
    &:hover {
      color: $red;
      opacity: 0.8;
    }
  }
}

.article-title {
  @include set-font-size($fz-title);
}

.article-date {
  @include set-font-size($fz-lable);
  color: $offWhite;
}
