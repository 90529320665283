.not-found {
  display: grid;
  place-content: center;
  min-height: 80vh;
  h1{
    @include glitch2;
    @include set-font-size($fz-display1);
    font-size: 10rem;
    line-height: 10rem;
    text-align: center;
  }
  h3{
    text-align: center;
    @include glitch2;
  }
}
