footer {
  width: 100vw;
  @include gridCenter();
  padding-top: 8rem;
  .footer-sns-icons {
    h1 {
      text-align: center;
      @include set-font-size($fz-headline);
    }
    ul {
      display: flex;
      place-content: center;
      padding: 1rem 0;
      li {
        transition: opacity 0.3s ease;
        margin: 1.5rem;
        a {
          i {
            font-size: 2.5rem;
          }
        }
        &:hover {
          opacity: 0.8;
          @include glitch;
        }
        @include sp {
          margin: 0.8rem;
          a {
            i {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
  .footer-nav-links {
    display: flex;
    li {
      padding: 0.5rem 2rem;
      @include set-font-size($fz-paragraph)
      &:hover{
        opacity: 0.6;
        // @include glitch;
      }
    }
    @include sp {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  .footer-logo {
    margin: 2rem auto;
    width: 3rem;
  }
  .copyright {
    text-align: center;
    padding: 0.5rem 0;
    @include set-font-size($fz-lable)
  }
}
