.shop-wrapper {
  width: 100%;
  min-height: 800px;
  display: grid;
  place-content: center;
  @include sp{
    min-height: 600px;
  }
  h1 {
    @include set-font-size($fz-title);
    text-align: center;
    padding: 3rem;
  }
  .countdown {
    display: flex;
    justify-content: space-around;
    .countdown-components {
      width: 230px;
      @include set-font-size($fz-display2)
      text-align: center;
      // @include glitch2;
      .countdown-title{
        @include set-font-size($fz-headline)
      }
    }
    @include sp{
      flex-direction: column;
      .countdown-components{
        width: auto;
        padding: 1rem;
      @include set-font-size($fz-display1) 
      .countdown-title{
        @include set-font-size($fz-subheading)
      }
      }
    }
  }
}
