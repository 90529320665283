.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
  transition: background-color 0.3s ease;
  .nav-logo {
    padding: 0 3rem;
    padding-top: 0.5rem;
  }
  ul {
    display: flex;
    padding-right: 3rem;
    .navbar__link{
      position: relative;
      &:hover {
        &:after{
          content: "";
          display: block;
          width: 60%;
          height: 1px;
          background: #fff;
          position: absolute;
          bottom: 24px;
          left: 0;
          right: 0;
          margin: auto;
          @include sp{
            width: 30%;
          }
        }
      }
      li {
        padding: 2rem 0.75rem;
        color: set-text-color($black);
        @include set-font-size($fz-lable);
        letter-spacing: 0.05em;
      }
    }
    .navbar__link--active{
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 60%;
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 24px;
        left: 0;
        right: 0;
        margin: auto;
        transition: .3s;
        @include sp{
          width: 30%;
        }
      }
    }
  }
  &-not-transparent {
    background-color: $black;
  }
  &-burger {
    display: none;
    padding: 1.5rem 0.5rem;
    cursor: pointer;
    div {
      width: 2rem;
      height: 0.2rem;
      background-color: $white;
      margin: 0.3rem;
      transition: all 0.3s ease;
    }
  }
  @include sp {
    // display: inline-block;
    height: 5rem;
    &-burger {
      display: block;
      position: absolute;
      z-index: 10;
    }
    .toggle {
      .line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
      }
    }
    .nav-logo {
      display: none;
    }
    .nav-links {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      padding: 3.5rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $red;
      transform: translateX(-100%);
      transition: transform 0.4s ease-out;
      min-width: 15rem;
      max-width: 100%;
      a {
        opacity: 0;
        @include set-font-size($fz-subheading);
        // transition: all 0.2s ease-in;
        // animation: (navLinkFade 1.2s ease 0.15s);
        &:hover {
          // background-color: #c00;
        }
        li {
          display: inline-block;
          padding: 2rem 3rem;
          width: inherit;
          text-align: center;
        }
      }
    }
    .nav-active {
      transform: translateX(0%);
      transition: transform 0.5s ease-in;
    }
  }
}