.news-list {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  }
  
  @media screen and (max-width: 768px) {
    .news-list {
      padding: 0 1.5rem;
    }
  }
  
  .news-col {
    max-width: 20rem;
    margin: 0 auto;
    transition: all ease 0.3s;
    * {
      flex-basis: 100%;
    }
    p {
      padding: 0.1rem 0;
      @include set-font-size($fz-lable);
      font-feature-settings : "pkna" 1;
    }
    img {
      width: 100%;
    }
    .news-date {
      color: $offWhite;
      @include set-font-size($fz-lable);
    }
  }
  