* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $white;
  font-family: proxima-nova, a-otf-gothic-bbb-pr6n, sans-serif;
  font-weight: bold;
}

body {
  background-color: $black;
  overflow-x: hidden;
}

h1 {
  @include set-font-size($fz-display1);
}
h2 {
  @include set-font-size($fz-headline);
}
h3 {
  @include set-font-size($fz-title);
}
h4 {
  @include set-font-size($fz-subheading);
}
p {
  @include set-font-size($fz-paragraph);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

$spaceamounts: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space / 2}rem;
  }
  .my-#{$space} {
    margin-top: #{$space / 2}rem;
    margin-bottom: #{$space / 2}rem;
  }
  .mx-#{$space} {
    margin-left: #{$space / 2}rem;
    margin-right: #{$space / 2}rem;
  }
  .p-#{$space} {
    padding: #{$space / 2}rem;
  }
  .py-#{$space} {
    padding-top: #{$space / 2}rem;
    padding-bottom: #{$space / 2}rem;
  }
  .px-#{$space} {
    padding-left: #{$space / 2}rem;
    padding-right: #{$space / 2}rem;
  }
}

%btn {
  display: inline-block;
  padding: 0.2rem 0.7rem;
  margin: 0.5rem 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  @include set-font-size($fz-lable);
  &:hover {
    // transform: scale(0.98);
  }
}

.btn01 {
  @extend %btn;
  border: solid $white 1px;
  &:hover {
    @include set-background($white);
  }
}

.btn02 {
  @extend %btn;
  background-color: $blue;
  border: none;
  margin: 2rem 0;
  &:hover {
    opacity: 0.7;
  }
}
