.music-list {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
}

@media screen and (max-width: 768px) {
  .music-list {
    padding: 0 1.5rem;
  }
}

.music-col {
  max-width: 20rem;
  margin: 0 auto;
  transition: all ease 0.3s;
  * &:hover{
    opacity: 0.6;
    transform: scale(105%);
  }
  * {
    flex-basis: 100%;
  }
  p {
    padding: 0.1rem 0;
    @include set-font-size($fz-paragraph);
  }
  img {
    width: 100%;
  }
  .music-date {
    color: $offWhite;
    @include set-font-size($fz-lable);
  }
  .music-artist-name {
    display: inline-block;
    padding-right: 1rem;
    color: $offWhite;
    @include set-font-size($fz-lable);
    transition: all 0.3s ease;
    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }
}
