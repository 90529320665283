.artist-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .artist-col {
    flex: 0 1 16rem;
    height: 16rem;
    filter: grayscale(20%);
    transition: all 0.3s ease;
    &:hover {
      filter: grayscale(0%);
      transform: scale(105%);
    }
    &:focus {
      filter: grayscale(90%);
      transform: scale(95%);
    }
    div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include gridCenter();
      position: relative;
      img {
        position: absolute;
        z-index: -1;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        height: auto;
        width: 100%;
      }
      h3 {
        @include set-font-size($fz-title);
      }
    }
  }
  @media (max-width: #{1360px}) {
    .artist-col {
      flex: 0 1 20rem;
      height: 20rem;
    }
  }
}

.artist-page-container {
  padding-top: 10rem;
  h1 {
    text-align: center;
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: 0.6rem;
    cursor: pointer;
    @include set-font-size($fz-display2);
    &:hover {
      @include glitch2;
    }
    @include sp{
      @include set-font-size($fz-display1);
    }
  }
  .artist-sns-links {
    padding: 2rem 0 5rem 0;
    ul {
      display: flex;
      place-content: center;
      li {
        margin: 1rem;

        a {
          i {
            font-size: 2.5rem;
            transition: all 0.3s ease;
            &:hover {
              opacity: 0.5;
              @include glitch;
            }
            @include sp{
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
  .music-list {
    padding: 5rem 0;
  }
}

.artist-video-wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}
.artist-video {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.artist-profile{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 5rem 0;
  .artist-photo{
    // border: solid 1px red;
    width: 19rem;
    height: 19rem;
    overflow: hidden;
    position: relative;
    img{
      width: 100%;
      position: absolute;
      left: -100%;
      right: -100%;
      top: -100%;
      bottom: -100%;
      margin: auto;
      min-height: 100%;
      min-width: 100%;
    }
  }
  .artist-bio{
    padding-left: 1.5rem;
    // border: solid 1px blue;
    flex: 1;
    height: auto;
    h3{
      @include set-font-size($fz-headline)
      padding-bottom:0.5rem;
    }
    p{
      @include set-font-size($fz-lable)
      font-feature-settings : "pkna" 1;
    }
  }
  @include sp{
    align-items: center;
    flex-direction: column;
    padding:2rem;
    .artist-photo{
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
    }
    .artist-bio{
      padding-left: 0;
      padding-top: 1rem;
    }
  }
}