$black: #0a0a0a;
$white: #fff;
$offWhite: #ccc;
$red: #f00;
$blue: #2670f9;
$fz-lable: 0.8rem;
$fz-paragraph: 1rem;
$fz-subheading: 1.25rem;
$fz-title: 1.563rem;
$fz-headline: 1.953rem;
$fz-display1: 2.441rem;
$fz-display2: 3.052rem;

$sp: 768px;

@mixin sp {
  @media (max-width: #{$sp}) {
    @content;
  }
}

// @media screen and (max-width: 768px) {

@function set-line-height($size) {
  @if ($size >= $fz-subheading) {
    @return ($size * 1.2);
  } @else {
    @return ($size * 1.5);
  }
}

@mixin set-font-size($size) {
  font-size: $size;
  line-height: set-line-height($size);
}

@function set-text-color($color) {
  @if (lightness($color) > 70) {
    @return $black;
  } @else {
    @return $white;
  }
}

@mixin set-background($color) {
  background-color: $color;
  color: set-text-color($color);
}

@mixin gridCenter {
  display: grid;
  place-content: center;
}

@keyframes navLinkFadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 100;
    transform: translateX(0px);
  }
}
@keyframes navLinkFadeOut {
  0% {
    opacity: 100;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@mixin glitch {
  text-shadow: 0.06rem 0 0.06rem #05f2db, -0.125rem 0 0.06rem #f23005;
  animation-duration: 0.01s;
  animation-name: textflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes textflicker {
  from {
    text-shadow:  1px 0 0 #05f2db, -4px 0 0 #f23005;
  }
  to {
    text-shadow: 4px 0.5px 2px #05f2db, -1.5px -0.5px 2px #f23005;
  }
}

@import "./Glitch";
@import "./Global";
@import "./Navbar";
@import "./Footer";
@import "./Hero";
@import "./Pages";
@import "./Artist";
@import "./Music";
@import "./News";
@import "./Contact";
@import "./Loader";
@import "./Error";
@import "./NotFound";
@import "./Shop";

