.hero {
  width: 100%;
  height: 100vh;
  @include gridCenter();
  .hero-video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
  .hero-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include gridCenter();
    z-index: 1;
    .hero-logo {
      width: 30vw;
      max-width: 14rem;
      margin: 0 auto;
    }
  }
}
