@mixin loader-flicker {
  animation-duration: 0.01s;
  animation-name: loaderflicker;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  .loader{
    @include set-font-size($fz-headline);
    @include loader-flicker;
  }
}

@keyframes loaderflicker {
  from {
    opacity: 100%;
  }
  to{
    opacity: 0%;
  }
}